import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Public pages
import Login from './pages/public/login/login';
import ResetPassword from './pages/public/reset-password/reset-password';
import Maintenance from './pages/public/maintenance/maintenance';
import OfferteBekijken from './pages/public/offerte/view-offerte';
import BedanktOfferte from './pages/public/offerte/offerte-bedankt';
import Enquete from './pages/public/enquete/enquete';
import BedanktEnquete from './pages/public/enquete/enquete-bedankt';

//Authenticated pages
import Dashboard from './pages/authenticated/dashboard/dashboard';
import Offerte from './pages/authenticated/offertes/offerte';
import OfferteBewerken from './pages/authenticated/offertes/offerte-bewerken';
import OpenstaandeOffertes from './pages/authenticated/offertes/openstaand';
import TeControlerenOffertes from './pages/authenticated/offertes/te-controleren';
import GoedgekeurdeOffertes from './pages/authenticated/offertes/goedgekeurd';
import AfgerondeOffertes from './pages/authenticated/offertes/afgerond';
import GearchiveerdeOffertes from './pages/authenticated/offertes/gearchiveerd';
import AfgewezenOffertes from './pages/authenticated/offertes/afgewezen';
import TeVerwerkenOffertes from './pages/authenticated/offertes/te-verwerken';
import Prijslijst from './pages/authenticated/offertes/prices';
import PrijslijstFtl from './pages/authenticated/offertes/prices-ftl';
import InAfwachtingOffertes from './pages/authenticated/offertes/in-afwachting';
import Voorbladen from './pages/authenticated/offertes/voorbladen';
import Rapportage from './pages/authenticated/rapportage/rapportage';
import Instellingen from './pages/authenticated/instellingen/instellingen';
import Menu from './pages/authenticated/menu/menu';


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <Routes>
       <Route index element={<Dashboard/>} />
       <Route path="login" element={<Login/>} />
       <Route path="wachtwoordherstel" element={<ResetPassword/>} />
       <Route path="onderhoud" element={<Maintenance/>} />
       <Route path="offerte-bekijken/:uuid" element={<OfferteBekijken />} />
       <Route path="bedankt/:status" element={<BedanktOfferte />} />
       <Route path="offerte" element={<Offerte/>} />
       <Route path="offerte-bewerken" element={<OfferteBewerken/>} />
       <Route path="openstaand" element={<OpenstaandeOffertes/>} />
       <Route path="te-controleren" element={<TeControlerenOffertes/>} />
       <Route path="prijslijst" element={<Prijslijst/>} />
       <Route path="prijslijst-ftl" element={<PrijslijstFtl/>} />
       <Route path="in-afwachting" element={<InAfwachtingOffertes/>} />
       <Route path="goedgekeurd" element={<GoedgekeurdeOffertes/>} />
       <Route path="afgerond" element={<AfgerondeOffertes/>} />
       <Route path="afgewezen" element={<AfgewezenOffertes/>} />
       <Route path="te-verwerken" element={<TeVerwerkenOffertes/>} />
       <Route path="gearchiveerd" element={<GearchiveerdeOffertes/>} />
       <Route path="voorbladen" element={<Voorbladen/>} />
       <Route path="rapportage" element={<Rapportage/>} />
       <Route path="instellingen" element={<Instellingen/>} />
       <Route path="enquete" element={<Enquete/>} />
       <Route path="bedankt-enquete" element={<BedanktEnquete/>} />
       <Route path="menu" element={<Menu/>} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
