import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, getFileContents } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import SignatureCanvas from 'react-signature-canvas';
import { Tooltip } from 'react-tooltip';
import { usePDF, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { TailSpin } from  'react-loader-spinner';
import { saveAs } from "file-saver";
import 'moment/locale/nl';
import moment from 'moment';

//Document
import OfferteDocument from '../../../components/offerte/offerte-document';

//Styles
import '../../../index.css';
import './view-offerte.css';
import 'react-tooltip/dist/react-tooltip.css';

//Assets
import vsdvLogo from '../../../assets/images/logo.svg';
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import { EraserFill } from 'react-bootstrap-icons';

const OfferteBekijken = () => {

    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL_GLOBAL;
    const environment = process.env.REACT_APP_APP_URL;
    const { uuid } = useParams();
    const [uuidState, setUuidState] = useState('');
    const [isLoading , setIsLoading] = useState(true);
    const [offerte, setOfferte] = useState([]);
    const [downloadPdf, setDownloadPdf]  = useState(false);
    const [validOfferte, setValidOfferte] = useState(false);
    const [pdf, setPdf] = useState('');
    const [iframePdf, setIframePdf] = useState('');
    const [kvkNummer, setKvkNummer] = useState('');
    const [kvkDisabled, setKvkDisabled] = useState(false);
    const [btwNummer, setBtwNummer] = useState('');
    const [emailFacturatie, setEmailFacturatie] = useState('');
    const [emailAdministratie, setEmailAdministratie] = useState('');
    const [telefoonAdministratie, setTelefoonAdministratie] = useState('');
    //const [pattern, setPattern] = useState("^NL\\d{9}B01$");
    const [pattern, setPattern] = useState("^NL\\d{9}B\\d+$");
    const [btwDisabled, setBtwDisabled] = useState(false);
    const [bedrijfsnaam, setBedrijfsnaam] = useState('');
    const [plaats, setPlaats] = useState('');
    const [voornaam, setVoornaam] = useState('');
    const [achternaam, setAchternaam] = useState('');
    const signatureRef = useRef(null);
    const [signatureImage, setSignatureImage] = useState('');
    const [showSide, setShowSide] = useState(false);
    const [reasonDeclined, setReasonDeclined] = useState('');
    const [sendingOfferte, setSendingOfferte] = useState(false);

    const fetchPdf = async (pdfUrl) => {
        if (!pdfUrl) {
            return setPdf('');
        }
        const response = await fetch(pdfUrl);
        const data = await response.blob();
        setPdf(URL.createObjectURL(data));
    };

    const getOfferte = async (uuid) => {
        try {
            const response = await api.get('/offertes/offerte-bekijken/' + uuid);
            if (response.data) {
                setOfferte(response.data);
                setKvkDisabled(response.data.klant.kvk_nummer ? true : false);
                setKvkNummer(response.data.klant.kvk_nummer);
                setBtwDisabled(response.data.klant.btw_nummer ? true : false);
                setBtwNummer(response.data.klant.btw_nummer);
                setEmailFacturatie(response.data.klant.email_facturatie);
                setEmailAdministratie(response.data.klant.email_administratie);
                setTelefoonAdministratie(response.data.klant.telefoon_administratie);
                setBedrijfsnaam(response.data.klant.bedrijfsnaam);
                //if (response.data.klant.land !== 'Nederland') {
                    setPattern("");
                //}
                //setVoornaam(response.data.contactpersoon.voornaam);
                //setAchternaam(response.data.contactpersoon.achternaam);
                //fetchPdf(environment + '' + response.data.versies[response.data.versies.length - 1].bestand.url);
                setIframePdf(await getFileContents(response.data.offertenummer, 'leeg', response.data.versies[response.data.versies.length - 1].pad));
                setValidOfferte(true);
                setIsLoading(false);
            }
        } catch (error) {
            setValidOfferte(false);
            setIsLoading(false);
        }
    }

    const uploadSignedPdf = async (offerte, blob, filename) => {
        setSendingOfferte(true);
        var formData = new FormData();
        formData.append('files', blob, filename);
        try {
            const response = await api.post('/offertes/upload/' + offerte.uuid, formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                setOfferteSigned(offerte, response.data[0]);
            }
        } catch (error) {
            setSendingOfferte(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const uploadSignedPdfFS = async (offerte, blob, filename, type, single) => {
        var formData = new FormData();
        formData.append("files", blob, filename);
        try {
            const response = await api.post('/offertes/upload-offerte/' + offerte.offertenummer, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                const path = response.data.filepaths[0];
                const fileName = path.match(/[^\\]+$/)[0];
                setOfferteSignedFS(offerte, fileName);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const setOfferteSignedFS = async (offerte, file) => {
        const versie = offerte.versies[offerte.versies.length - 1].versie;
        const versies = offerte.versies;
        const fileObj = {
            "versie": versie.toString(),
            "pad": file,
            "getekend": true
        }
        versies.push(fileObj);
        const log = {
            "omschrijving": 'Offerte door klant (' + voornaam + ' ' + achternaam + ') goedgekeurd',
            "datum_tijd": new Date().toISOString()
        };
        offerte.logs.unshift(log);
        const workflowArray = [
            {naam: 'Debiteurenbeheer', info: 'Debiteurenbeheer is bezig met het invoeren', voltooid: false, log: ''},
            {naam: 'Tariefinrichting', info: 'Tariefinrichting is bezig met het invoeren', voltooid: false, log: ''},
            {naam: 'Sales', info: 'Sales is accounts in juiste portals aan het inrichten en brengt klant op de hoogte', voltooid: false, log: ''},
            {naam: 'Marketing', info: 'Marketing/HR zorgt voor presentje nieuwe klant', voltooid: false, log: ''},
            {naam: 'Afgerond', info: 'Workflow is volledig doorlopen en alle taken zijn voltooid', voltooid: false, log: ''},
        ];
        try {
            const response = await api.post('/offertes/sign/' + offerte.uuid, {
                data: {
                    status: 'goedgekeurd',
                    versies: versies,
                    logs: offerte.logs,
                    workflow: workflowArray,
                    kvk_nummer: kvkNummer,
                    btw_nummer: btwNummer,
                    email_facturatie: emailFacturatie,
                    email_administratie: emailAdministratie,
                    telefoon_administratie: telefoonAdministratie,
                    getekend_door: voornaam + ' ' + achternaam,
                    goedgekeurd_op: moment().format('YYYY-MM-DD')
                }
            });
            if (response.data.id) {
                navigate('/bedankt/goedgekeurd');
            } else {
                notifyError('Er is iets mis gegaan met het versturen');
            }
        } catch (error) {
            setSendingOfferte(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const setOfferteSigned = async (offerte, file) => {
        const versie = offerte.versies[offerte.versies.length - 1].versie;
        const versies = offerte.versies;
        const fileObj = {
            "versie": versie.toString(),
            "bestand": {
                "id": file.id
            },
            "getekend": true
        }
        versies.push(fileObj);
        const log = {
            "omschrijving": 'Offerte door klant (' + voornaam + ' ' + achternaam + ') goedgekeurd',
            "datum_tijd": new Date().toISOString()
        };
        offerte.logs.unshift(log);
        const workflowArray = [
            {naam: 'Debiteurenbeheer', info: 'Debiteurenbeheer is bezig met het invoeren', voltooid: false, log: ''},
            {naam: 'Tariefinrichting', info: 'Tariefinrichting is bezig met het invoeren', voltooid: false, log: ''},
            {naam: 'Sales', info: 'Sales is accounts in juiste portals aan het inrichten en brengt klant op de hoogte', voltooid: false, log: ''},
            {naam: 'Marketing', info: 'Marketing/HR zorgt voor presentje nieuwe klant', voltooid: false, log: ''},
            {naam: 'Afgerond', info: 'Workflow is volledig doorlopen en alle taken zijn voltooid', voltooid: false, log: ''},
        ];
        try {
            const response = await api.post('/offertes/sign/' + offerte.uuid, {
                data: {
                    status: 'goedgekeurd',
                    versies: versies,
                    logs: offerte.logs,
                    workflow: workflowArray,
                    kvk_nummer: kvkNummer,
                    btw_nummer: btwNummer,
                    email_facturatie: emailFacturatie,
                    email_administratie: emailAdministratie,
                    telefoon_administratie: telefoonAdministratie,
                    getekend_door: voornaam + ' ' + achternaam,
                    goedgekeurd_op: moment().format('YYYY-MM-DD')
                }
            });
            if (response.data.id) {
                navigate('/bedankt/goedgekeurd');
            } else {
                notifyError('Er is iets mis gegaan met het versturen');
            }
        } catch (error) {
            setSendingOfferte(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const signOfferte = async (event) => {
        event.preventDefault();
        if (!downloadPdf) {
            const emptyCanvas = signatureRef.current.isEmpty();
            if (emptyCanvas) {
                return alert('Er is nog geen handtekening ingevuld');
            }
            const canvas = signatureRef.current.getCanvas();
            const base64Image = canvas.toDataURL();
            setSignatureImage(base64Image);
            const currentOfferte = {...offerte};
            currentOfferte.klant.kvk_nummer = kvkNummer;
            currentOfferte.klant.btw_nummer = btwNummer;
            currentOfferte.klant.email_facturatie = emailFacturatie;
            currentOfferte.klant.email_administratie = emailAdministratie;
            currentOfferte.klant.telefoon_administratie = telefoonAdministratie;
            setDownloadPdf(true);
        }
    }

    const declineOfferte = async (event) => {
        event.preventDefault();
        const log = {
            "omschrijving": 'Offerte afgewezen door klant (' + voornaam + ' ' + achternaam + ')',
            "datum_tijd": new Date().toISOString()
        };
        offerte.logs.unshift(log);
        try {
            const response = await api.post('/offertes/decline/' + offerte.uuid, {
                data: {
                    status: 'afgewezen',
                    afgewezen_door: voornaam + ' ' + achternaam,
                    reden_afgewezen: reasonDeclined,
                    logs: offerte.logs
                }
            });
            if (response.data) {
                navigate('/bedankt/afgewezen');
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    useEffect(() => {
        getOfferte(uuid);
        var timer = 0;
        const intervalId = setInterval(() => {
            timer += 1;
        }, 1000);

        const sendViewingData = (uuid) => {
            const sendData = { tijd_bekeken: timer, datum: moment().format('YYYY-MM-DD'), tijdstip: moment().format('HH:mm') };
            const headers = {
                type: 'application/json',
            };
            const blob = new Blob([JSON.stringify(sendData)], headers);
            navigator.sendBeacon(apiUrl + '/offertes/statistieken/' + uuid, blob);
        };

        const handleBeforeUnload = () => {
            sendViewingData(uuid);
            clearInterval(intervalId);
        };

        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (isLoading) {
        return (
            <TailSpin
                height="80"
                width="80"
                color="#001c43"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{width:'100%',justifyContent:'center',marginTop: 100}}
                wrapperClass=""
                visible={true}
            />
        )
    }

    if (!validOfferte) {
        return (
            <div id="blocked" style={{textAlign:'center', marginTop: 100}}>
                <p style={{fontSize:20}}>Er is helaas niks gevonden...</p>
            </div>
        )
    }

    return (
        <div id="sign-offerte">
            <div className="content">
                <div className="container">
                    <div className="sign-header">
                        <img src={vsdvLogo} className="logo" />
                        <h2>{offerte.klant.nieuwe_klant ? 'Offerte ondertekenen' : 'Tarieven goedkeuren'}</h2>
                    </div>
                    <div className="pdf-content">
                        <div className="pdf-viewer">
                            <div className="viewer-content">
                                <iframe src={`${iframePdf}#view=fitH`} title="view PDF" height="700" width="100%" allow="fullscreen"></iframe>
                                {
                                    offerte.bijlagen.length > 0 || offerte.dieseltoeslag ?
                                    <div className="attachments">
                                        <h4>Bijlagen:</h4>
                                        <ul className="attachments-list">
                                        {
                                            offerte.bijlagen.length > 0 ?
                                            <>
                                            {
                                                offerte.bijlagen.map((item, index) =>  {
                                                    return <li key={index} onClick={() => { saveAs(environment + '' + item.bestand.url, item.bestand.name) }}><Icon.FilePdf />{item.naam}</li>
                                                })
                                            }
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            offerte.soort_dieseltoeslag ?
                                            <li onClick={() => { saveAs(environment + '' + offerte.soort_dieseltoeslag.pdf.url, offerte.soort_dieseltoeslag.label) }}><Icon.FilePdf />{offerte.soort_dieseltoeslag.label}</li>
                                            :
                                            null
                                        }
                                        </ul>
                                        
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="pdf-form">
                            <div className="sign-form-content">
                                <div className="form-header">
                                    <h3>Vul onderstaande gegevens in</h3>
                                    {
                                        downloadPdf && bedrijfsnaam && plaats && voornaam && achternaam && signatureImage ?
                                        null
                                        :
                                        <button className="decline red" onClick={() => setShowSide(true)}>{offerte.klant.nieuwe_klant ? 'Offerte afwijzen' : 'Tarieven afwijzen'}</button>
                                    }
                                    
                                </div>
                                <form id="signing-form" onSubmit={signOfferte}>
                                        <div className="input-field">
                                            <input type="email" placeholder="E-mail facturatie*" className="form-field" value={emailFacturatie ? emailFacturatie : ''} onChange={(e) => setEmailFacturatie(e.target.value)} required />
                                            { emailFacturatie ? <span className="placeholder">E-mail facturatie</span> : null }
                                        </div>
                                        <div className="two-column">
                                            <div className="input-field">
                                                <input type="email" placeholder="E-mail administratie" className="form-field" value={emailAdministratie ? emailAdministratie : ''} onChange={(e) => setEmailAdministratie(e.target.value)} />
                                                { emailAdministratie ? <span className="placeholder">E-mail administratie</span> : null }
                                            </div>
                                            <div className="input-field">
                                                <input type="text" placeholder="Telefoon administratie" className="form-field" value={telefoonAdministratie ? telefoonAdministratie : ''} onChange={(e) => setTelefoonAdministratie(e.target.value)} />
                                                { telefoonAdministratie ? <span className="placeholder">Telefoon administratie</span> : null }
                                            </div>
                                        </div>
                                    {
                                        kvkDisabled && btwDisabled ?
                                        null
                                        :
                                        <div className="two-column">
                                            <div className="input-field">
                                                <input type="text" placeholder="KVK Nummer*" className="form-field" value={kvkNummer ? kvkNummer : ''} onChange={(e) => setKvkNummer(e.target.value)} required disabled={kvkDisabled} />
                                                { kvkNummer ? <span className="placeholder">KVK</span> : null }
                                            </div>
                                            <div className="input-field">
                                                <input type="text" placeholder="BTW Nummer*" className="form-field" value={btwNummer ? btwNummer : ''} onChange={(e) => setBtwNummer(e.target.value)} required disabled={btwDisabled} />
                                                { btwNummer ? <span className="placeholder">BTW</span> : null }
                                            </div>
                                        </div>
                                    }
                                    <div className="two-column">
                                        <div className="input-field">
                                            <input type="text" placeholder="Bedrijfsnaam*" className="form-field" value={bedrijfsnaam} onChange={(e) => setBedrijfsnaam(e.target.value)} required disabled={true} />
                                        </div>
                                        <div className="input-field">
                                            <input type="text" placeholder="Plaats*" className="form-field" value={plaats} onChange={(e) => setPlaats(e.target.value)} required disabled={downloadPdf && bedrijfsnaam && plaats && voornaam && achternaam && signatureImage} />
                                            { plaats ? <span className="placeholder">Plaats</span> : null }
                                        </div>
                                    </div>
                                    <div className="two-column">
                                        <div className="input-field">
                                            <input type="text" placeholder="Voornaam*" className="form-field" value={voornaam} onChange={(e) => setVoornaam(e.target.value)} required disabled={downloadPdf && bedrijfsnaam && plaats && voornaam && achternaam && signatureImage} />
                                            { voornaam ? <span className="placeholder">Voornaam</span> : null }
                                        </div>
                                        <div className="input-field">
                                            <input type="text" placeholder="Achternaam*" className="form-field" value={achternaam} onChange={(e) => setAchternaam(e.target.value)} required disabled={downloadPdf && bedrijfsnaam && plaats && voornaam && achternaam && signatureImage} />
                                            { achternaam ? <span className="placeholder">Achternaam</span> : null }
                                        </div>
                                    </div>
                                    {
                                        downloadPdf && bedrijfsnaam && plaats && voornaam && achternaam && signatureImage ?
                                        <img src={signatureImage} />
                                        :
                                        <div className="sign-canvas-container">
                                            <SignatureCanvas ref={signatureRef} penColor='black' backgroundColor='white' canvasProps={{className: 'sign-canvas'}} />
                                            <span className="signing-placeholder">Handtekening</span>
                                            <span id="erase" onClick={() => {signatureRef.current.clear(); setDownloadPdf(false) }}><EraserFill /></span>
                                            <Tooltip
                                                anchorId={'erase'}
                                                place="top"
                                                content={'Verwijder handtekening'}
                                            />
                                        </div>
                                    }
                                    <div className="sign-form-footer">
                                        {
                                            downloadPdf && emailFacturatie && bedrijfsnaam && plaats && voornaam && achternaam && signatureImage ?
                                            <BlobProvider document={<OfferteDocument offerte={offerte} versie={offerte.versies[offerte.versies.length - 1].versie} signCompany={bedrijfsnaam} signPlace={plaats} signFirstName={voornaam} signLastName={achternaam} signatureImage={signatureImage} />}>
                                                {({ blob, url, loading, error }) => ( 
                                                    loading ? <TailSpin
                                                                height="30"
                                                                width="30"
                                                                color="#001c43"
                                                                ariaLabel="tail-spin-loading"
                                                                radius="1"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                            /> 
                                                :
                                                <div className="sending-actions">
                                                    <button className="green" onClick={() => { uploadSignedPdfFS(offerte, blob, 'offerte-' + offerte.offertenummer + '-getekend-' + (offerte.versies[offerte.versies.length - 1].versie) + '.pdf'); }} disabled={sendingOfferte}>Akkoord en versturen</button>
                                                    <button className="red" onClick={() => setDownloadPdf(false)}>Wijzig gegevens</button>
                                                </div>
                                                )}
                                            </BlobProvider>
                                            :
                                            <div className="buttons">
                                                <input type="submit" value="Klaar om te versturen" className="green" />
                                            </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div className={'decline-side side-preview ' + (showSide ? 'show' : '')}>
                <div className="side-preview-header">
                    <h3>{offerte.klant.nieuwe_klant ? 'Offerte afwijzen' : 'Tarieven afwijzen'}</h3>
                    <Icon.XCircleFill onClick={() => setShowSide(false)} />
                </div>
                <div className="side-preview-content">
                    <form id="decline-offerte" onSubmit={declineOfferte}>
                        <div className="two-column">
                            <div className="input-field">
                                <input type="text" placeholder="Voornaam*" className="form-field" value={voornaam} onChange={(e) => setVoornaam(e.target.value)} required />
                            </div>
                            <div className="input-field">
                                <input type="text" placeholder="Achternaam*" className="form-field" value={achternaam} onChange={(e) => setAchternaam(e.target.value)} required />
                            </div>
                        </div>
                        <textarea placeholder="Reden van afwijzing" className="form-field" value={reasonDeclined} onChange={(e) => setReasonDeclined(e.target.value)} required></textarea>
                        <input type="submit" value="Afwijzen en versturen" className="red" />
                    </form>
                </div>
            </div>

            <img src={vsdvArtwork} className="artwork" />
        </div>
      );
  
};
export default OfferteBekijken;