//3rd party
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { Document, View, Image, Page, Text, Font, StyleSheet } from '@react-pdf/renderer';

//Component
import Inhoudsopgave from './inhoudsopgave';

//Assets
import pdfLogo from '../../assets/images/pdf-logo.png';
import pdfArtwork from '../../assets/images/artwork.png';
import pdfHeader from '../../assets/images/header-offerte-right.png';
import PrometoBold from '../../assets/fonts/Prometo-Bold.ttf';
import PrometoMedium from '../../assets/fonts/Prometo-Medium.ttf';
import PrometoMediumItalic from '../../assets/fonts/Prometo-MediumItalic.ttf';
import PrometoRegular from '../../assets/fonts/Prometo-Regular.ttf';
import PrometoLight from '../../assets/fonts/Prometo-Light.ttf';
import PrometoLightItalic from '../../assets/fonts/Prometo-LightItalic.ttf';

// Register font
Font.register({family: 'PrometoRegular', src: PrometoRegular });
Font.register({family: 'PrometoMedium', src: PrometoMedium });
Font.register({family: 'PrometoBold', src: PrometoBold });
Font.register({family: 'PrometoMediumItalic', src: PrometoMediumItalic });
Font.register({family: 'PrometoLight', src: PrometoLight });
Font.register({family: 'PrometoLightItalic', src: PrometoLightItalic });

// Create styles
const styles = StyleSheet.create({
    firstPage: {
        padding: 35,
        fontSize: 12,
        color: '#001E4E'
    },
    imageSection: {
        height: 480
    },  
    headerLogo: {
        marginTop: -485,
        marginRight: -5
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 290,
        backgroundColor: '#EAF3F8',
        padding: 15
    },
    version: {
        position: 'absolute',
        right: 15,
        bottom: 15,
        fontFamily: 'PrometoRegular',
        fontSize: 11
    },
    title: {
        fontFamily: 'PrometoBold',
        fontSize: 28,
        textTransform: 'uppercase',
        marginTop: 5,
        marginBottom: 5
    },
    subtitle: {
        fontFamily: 'PrometoBold',
        fontSize: 20,
        marginBottom: 10
    },
    receiverInfo: {
        fontFamily: 'PrometoRegular'
    },
    page: {
        paddingLeft: 50,
        paddingTop: 25,
        paddingRight: 50,
        paddingBottom: 75,
        fontSize: 12,
        color: '#001E4E'
    },
    pageHeader: {
        borderBottom: 1,
        borderColor: 'black',
        marginBottom: 15
    },
    pageHeaderLogo: {
        width: 40,
        alignSelf: 'center',
        marginBottom: 10
    },
    pageFooter: {
        position: 'absolute',
        bottom: 25,
        left: 50,
        right: 50,
        borderBottom: 1,
        borderColor: 'black',
    },
    pageFooterContent: {
        position: 'relative',
        alignSelf: 'center'
    },
    pageFooterArtwork: {
        width: 120
    },
    pageNumber: {
        fontFamily: 'PrometoRegular',
        position: 'absolute',
        left: 60,
        bottom: 0
    },
    pageTitle: {
        color: 'black',
        fontFamily: 'PrometoBold',
        fontSize: 18,
        marginBottom: 20,
    },
    paragraph: {
        color: 'black',
        fontFamily: 'PrometoRegular',
        fontSize: 10,
        marginBottom: 15
    },
    inhoudsItem: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    inhoudSubItemHeader: {
        color: 'black',
        fontFamily: 'PrometoBold',
        fontSize: 14,
        marginTop: 15
    },
    inhoudsSubjectHeader: {
        fontFamily: 'PrometoLight',
        fontSize: 10
    },
    inhoudSubitem: {
        marginBottom: 3,
        paddingLeft: 15
    },
    inhoudsSpace: {
        position: 'relative',
        flexGrow: 1
    },
    dots: {
        position: 'absolute',
        left: 3,
        right: 0,
        bottom: -5,
        borderBottom: 1,
        borderColor: 'black',
        borderStyle: 'dotted',
    },
    dotsSmall: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -3,
        borderBottom: 1,
        borderColor: 'black',
        borderStyle: 'dotted',
    },
    inhoudsPageNumber: {
        fontFamily: 'PrometoLight',
        fontSize: 11
    },
    subHeader: {
        color: 'black',
        fontFamily: 'PrometoBold',
        fontSize: 13,
        marginBottom: 10,
    },
    subItemHeader: {
        color: 'black',
        fontFamily: 'PrometoBold',
        fontSize: 10,
        marginBottom: 5,
    },
    alinea: {
        fontFamily: 'PrometoLight',
        fontSize: 9,
        lineHeight: 1.3
    },
    toeslagenItem: {
        paddingBottom: 25
    },
    toeslagenSubItem: {
        paddingBottom: 10
    },
    signBlock: {
        marginBottom: 25
    },
    signBlockHeader: {
        color: 'black',
        fontFamily: 'PrometoBold',
        fontSize: 11,
        marginBottom: 10,
    },
    signItem: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 2
    },
    signItemLabel: {
        minWidth: 120,
        fontFamily: 'PrometoLight',
        fontSize: 9
    },
    signItemValue: {
        fontFamily: 'PrometoRegular',
        fontSize: 9
    },
    signAlinea: {
        fontFamily: 'PrometoLight',
        fontSize: 9,
        lineHeight: 1.3
    },
    signColumns: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 70,
        marginTop: 25
    },
    signColumn: {
        width: 175
    },
    signInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 15
    },
    signDocumentLabel: {
        minWidth: 80,
        fontFamily: 'PrometoLight',
        fontSize: 9
    },
    signDocumentValue: {
        fontFamily: 'PrometoRegular',
        fontSize: 9
    },
    companyName: {
        fontFamily: 'PrometoLight',
        fontSize: 9
    },
    signatureArea: {
        height: 100,
        borderBottomWidth: 0.5,
        borderColor: 'black',
        marginTop: 10,
        marginBottom: 5
    },
    signatureImage: {
        height: 90,
        objectFit: 'contain'
    },
    table: { 
        //display: "table", 
        width: "auto",
        marginTop: 15,
        marginBottom: 15
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row"
      },
      tableColHeader: {
        borderStyle: "solid", 
        borderWidth: 0.5,
        borderColor: 'white',
        backgroundColor: '#001C43',
        color: 'white',
        borderLeftWidth: 0, 
        borderTopWidth: 0
      }, 
      tableCol: {
        borderStyle: "solid", 
        borderWidth: 0.5,
        borderColor: 'white',
        backgroundColor: '#EAF3F8',
        borderLeftWidth: 0, 
        borderTopWidth: 0
      },
      priceTableCol: {
        textAlign: 'center',
        height: 18
      },
      redCol: {
        backgroundColor: '#CB0A32'
      },
      twoCol: {
        width: '50%'
      },
      threeCol: {
        width: '33%'
      },
      fourCol: {
        width: '25%'
      },
      tableCell: { 
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10,
        color: '#001C43',
        fontFamily: 'PrometoRegular',
        fontSize: 9
      },
      tableCellRed: {
        color: 'white'
      },
      tableCellHeader: { 
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 5,
        marginRight: 5,
        color: 'white',
        fontFamily: 'PrometoBold',
        fontSize: 9
      },
      tableCellHeaderLadingSoort: {
        marginLeft: 5,
        marginRight: 5,
        color: 'white',
        fontFamily: 'PrometoRegular',
        fontSize: 8
      },
      tableInhouden: {
        width: '25%'
      },
      priceList: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      priceListCol: {
        width: '100%',
        height: 18,
        textAlign: 'center'
      },
      priceListFtlCol: {
        height: 'auto'
      },
      zonelist: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      zoneTable: {
        marginTop: 0,
        marginBottom: 5,
        width: '20%'
      },
      bijlageItem: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
        gap: 10
      },
      bijlageNumber: {
        color: 'black',
        fontFamily: 'PrometoBold',
        fontSize: 11
      },
      bijlageName: {
        color: 'black',
        fontFamily: 'PrometoRegular',
        fontSize: 11
      },
});

const PageHeader = () => {
    return <View style={styles.pageHeader}>
        <Image src={pdfLogo} style={styles.pageHeaderLogo} />
    </View>
}
const PageFooter = (props) => {
    return <View style={styles.pageFooter}>
        <View style={styles.pageFooterContent}>
            <Image src={pdfArtwork} style={styles.pageFooterArtwork} />
            <Text style={styles.pageNumber}>{props.pageNumber}</Text>
        </View>
    </View>
}

const OfferteDocument = (props) => {

    const environment = process.env.REACT_APP_APP_URL;
    const offerte = props.offerte;
    var maxPageLength = 2100;

    var tijdstoeslagen = offerte.toeslagen.filter(function(item) {
        return item.toeslag.type === 'tijd';
    });
    var douanetoeslagen = offerte.toeslagen.filter(function(item) {
        return item.toeslag.type === 'douane';
    });
    var afstapeltoeslagen = offerte.toeslagen.filter(function(item) {
        return item.toeslag.type === 'afstapel';
    });

    const returnValueWithEenheid = (value, eenheid) => {
        if (eenheid === 'laadmeters' || eenheid === 'laadmeters_custom') {
            return '= ' + value.laadmeters + ' m';
        }
        else if (eenheid === 'gewicht') {
            return '';
        } else {
            return ''
        }
    }

    const arrSoorten = [];
    offerte.ladingsoorten.map((item, index) =>  {
        arrSoorten.push(item.code + ' ' + returnValueWithEenheid(item, offerte.ladingtype.key));
    })

    const arrLabels = [];
    const arrLabelsTwo = [];
    arrLabels.push(offerte.ladingtype.afkorting);
    arrLabelsTwo.push(offerte.ladingtype.afkorting);
    if (offerte.twee_ltl) {
       if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 2) {
            arrLabels[1] = offerte.kolommen_tonen[0].code;
            arrLabelsTwo[1] = offerte.kolommen_tonen[1].code;
       }
       if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 1) {
            arrLabels[1] = offerte.kolommen_tonen[0].code;
       }
    } else {
        offerte.kolommen_tonen.map((item, index) =>  {
            arrLabels.push(item.code);
        })
    }
    if (offerte.ladingtype.key !== 'gewicht' && offerte.toon_gewicht) {
        arrLabels.push('KG');
        arrLabelsTwo.push('KG');
    }

    if (offerte.rijen_inhouden) {
        if (offerte.rijen_inhouden.length > 0) {
            if (offerte.rijen_inhouden[0][0].items.length === 2) {
                var priceColWidth = '50%';
            } else if (offerte.rijen_inhouden[0][0].items.length === 3) {
                var priceColWidth = '33.33%';
            } else if (offerte.rijen_inhouden[0][0].items.length === 4) {
                var priceColWidth = '25%';
            } else {
                var priceColWidth = '100%';
            }
        } else {
            var priceColWidth = '100%';
        }
    } else {
        var priceColWidth = '100%';
    }

    function calcRowHeightFinal(columns, prijzenIndex) {
        var heighestValue = 0;
        columns.map((item, index) =>  {
            if (item.prijzen[prijzenIndex][0].height > heighestValue) {
                heighestValue = item.prijzen[prijzenIndex][0].height;
            }
        })
        return heighestValue;
    }


    function calcRowHeight(columns, valueLength) {
        const charactersPerLine = 130 / columns;
        const result = valueLength / charactersPerLine;
        const roundedNumber = Math.ceil(result);
        var rowHeight = 18 * roundedNumber;
        return rowHeight;
    }
    
    function splitFtlPrices(originalArray) {
        if (originalArray.length === 0) {
            return [];
        }
        const firstRow = [];
        originalArray.map((item, index) =>  {
            firstRow.push(item.prijzen[0]);
        });
        const resultArray = [];
        const maxItemsPerRow = 30;
        const fullRows = Math.ceil(originalArray[0].prijzen.length / maxItemsPerRow);
        resultArray.push(originalArray.map((item) => ({
            id: item.id,
            prijzen: item.prijzen.slice(0, maxItemsPerRow),
        })));
        for (let i = 1; i < fullRows; i++) {
            const startIndex = i * maxItemsPerRow;
            const endIndex = Math.min((i + 1) * maxItemsPerRow, originalArray[0].prijzen.length);
            const newRow = originalArray.map((item) => ({
                id: item.id,
                prijzen: item.prijzen.slice(startIndex, endIndex),
            }));
            resultArray.push(newRow);
        }
        resultArray[0].map((item, index) =>  {
            item.prijzen.map((subItem, subIndex) =>  {
                subItem[0].height = calcRowHeight(resultArray[0].length, subItem[0].value ? subItem[0].value.length : 0);
            })
        })
        resultArray[0].map((item, index) =>  {
            item.prijzen.map((subItem, subIndex) =>  {
                subItem[0].height = calcRowHeightFinal(resultArray[0], subIndex);
            })
        })
        return resultArray;
    }

    function groupArrayByNItems(array, n) {
        const groupedArray = [];
        for (let i = 0; i < array.length; i += n) {
          groupedArray.push(array.slice(i, i + n));
        }
        return groupedArray;
      }
      const groupSize = 5;
      const groupedZones = groupArrayByNItems(offerte.zones, groupSize);
      const groupedZonesTwee = groupArrayByNItems(offerte.twee_ltl ? offerte.zones_twee : [], groupSize);
      const groupedPricesFtl = splitFtlPrices(offerte.prijzen_ftl);

    //Function to split array for fitting on page
    function splitArrayByLength(arr, maxLength) {
        let result = [];
        let currentArray = [];
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
          if (sum + arr[i].totale_lengte <= maxLength) {
            arr[i]['itemNumber'] = i + 1;
            currentArray.push(arr[i]);
            sum += arr[i].totale_lengte;
          } else {
            result.push(currentArray);
            arr[i]['itemNumber'] = i + 1;
            currentArray = [arr[i]];
            sum = arr[i].totale_lengte;
          }
        }
        if (currentArray.length > 0) {
            result.push(currentArray);
        }
        return result;
    }

    const getTotalLengthToeslag = (table, content) => {
        var total = (table.length * 120);
        total += content.alinea_een ? content.alinea_een.length : 0;
        total += content.alinea_twee ? content.alinea_twee.length : 0;
        return total;
    }

    const replaceValues = (regexName, currentValue, value) => {
        if (value === null) {
            value = '';
        }
        var regex = new RegExp('\\{' + regexName + '\\|([^}]+)\\}');
        var match = currentValue.match(regex);
        if (match) {
            var valueAfterPipe = match[1];
            var replacement = value !== '' ? value : valueAfterPipe;
            var newValue = currentValue.replace(regex, replacement);
            return newValue;
        }
        return currentValue;
    }

    const sortTotalToeslagen = (name, key, contents) => {
        const obj = {
            titel: name,
            totale_lengte: getTotalLengthToeslag(key, offerte.divisie[key]),
            alinea_een: offerte.divisie[key].alinea_een,
            table_contents: contents,
            columns: ['Soort toeslag', 'Eenheid', 'Prijs per eenheid', 'Opmerking'],
            alinea_twee: offerte.divisie[key].alinea_twee
        }
        return obj;
    }
    const totalToeslagen = [];
    if (tijdstoeslagen.length > 0) {
        totalToeslagen.push(sortTotalToeslagen('Tijdstoeslagen', 'tijdstoeslagen', tijdstoeslagen));
    }
    if (douanetoeslagen.length > 0) {
        totalToeslagen.push(sortTotalToeslagen('Douanetoeslagen', 'douanetoeslagen', douanetoeslagen));
    }
    if (afstapeltoeslagen.length > 0) {
        totalToeslagen.push(sortTotalToeslagen('Overige toeslagen', 'afstapeltoeslagen', afstapeltoeslagen));
    }
    if (offerte.wachtkosten.length > 0) {
        totalToeslagen.push({
            titel: 'Wachtkosten',
            totale_lengte: getTotalLengthToeslag(offerte.wachtkosten, offerte.divisie.wachtkosten),
            alinea_een: replaceValues('bedrag_wachtkosten', offerte.divisie.wachtkosten.alinea_een, offerte.divisie_variabelen ? offerte.divisie_variabelen.bedrag_wachtkosten : ''),
            table_contents: offerte.wachtkosten,
            columns: ['Palletaantal', 'maximale laad- en lostijd'],
            alinea_twee: offerte.divisie.wachtkosten.alinea_twee
        })
    }

    const pagesToeslagen = splitArrayByLength(totalToeslagen, maxPageLength);
    if (pagesToeslagen[0].length === 0) {
        pagesToeslagen.shift();
    }
    const lastItemPagesToeslagen = pagesToeslagen[pagesToeslagen.length - 1];
    const startNumberPageToeslagen = offerte.zones.length > 0 ? 4 : 3;
    if (offerte.ftl && offerte.ltl) {
        var firstPageToeslagen = startNumberPageToeslagen + groupedPricesFtl.length + groupedZones.length + 1;
        var zonesPageNumber = 4 + groupedPricesFtl.length + groupedZones.length;
    } else if (offerte.ftl && !offerte.ltl) {
        var firstPageToeslagen = startNumberPageToeslagen + groupedPricesFtl.length + 1;
        var zonesPageNumber = 4 + groupedPricesFtl.length;
    } else {
        var firstPageToeslagen = startNumberPageToeslagen + groupedZones.length + 1;
        var zonesPageNumber = 4 + groupedZones.length;
    }
    //const firstPageToeslagen = offerte.ftl ? (startNumberPageToeslagen + groupedPricesFtl.length) + 1 : (startNumberPageToeslagen + groupedZones.length) + 1;
    const itemNumberVrachtToeslagen = lastItemPagesToeslagen[lastItemPagesToeslagen.length - 1].itemNumber + 1;

    const valuesToReplaceVrachttoeslagen = [
        {key: 'percentage_waddentoeslag', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.percentage_waddentoeslag : ''},
        {key: 'percentage_doorleveringen', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.percentage_doorleveringen : ''},
        {key: 'percentage_tweede_levering', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.percentage_tweede_levering : ''},
        {key: 'percentage_retourneren', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.percentage_retourneren : ''},
    ];
    valuesToReplaceVrachttoeslagen.forEach(replaceObj => {
        offerte.divisie.vrachttoeslagen.forEach(toeslag => {
            toeslag.content = replaceValues(replaceObj.key, toeslag.content, replaceObj.value);
        });
    });

    const valuesToReplaceAlgemeneInformatie = [
        {key: 'tijdstip_opgave_orders', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.tijdstip_opgave_orders : ''},
        {key: 'tijdstip_zendingen_beschikbaarheid', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.tijdstip_zendingen_beschikbaarheid : ''},
        {key: 'percentage_europallets', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.percentage_europallets : ''},
        {key: 'aantal_dagen_reclames', value: offerte.divisie_variabelen ? offerte.divisie_variabelen.aantal_dagen_reclames : ''},
        {key: 'betaaltermijn', value: offerte.betalingstermijn}
    ];
    valuesToReplaceAlgemeneInformatie.forEach(({ key, value }) => {
        offerte.divisie.algemene_informatie.forEach(item => {
            item.subonderwerpen.forEach(subItem => {
                subItem.content = replaceValues(key, subItem.content, value);
            });
        });
    });

    //Get total algemene informatie content length for calculating pages
    var totalAlgemeneInformatie = [];
    offerte.divisie.algemene_informatie.map((item, index) =>  {
        var contentLength = 0;
        item.subonderwerpen.map((subItem, subIndex) => {
            contentLength += subItem.content.length;
        })
        var obj = {
            'titel': item.titel,
            'totale_lengte': contentLength,
            'subonderwerpen': item.subonderwerpen
        } 
        totalAlgemeneInformatie.push(obj);
    })
    const pagesAlgemeneInformatie = splitArrayByLength(totalAlgemeneInformatie, maxPageLength);

    const formatBedrag = (number) => {
        const formattedBedrag = number.toLocaleString('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2, 
        });
        return formattedBedrag;
    }

    const signing = {
        gegevens: [
            {
                label: 'KVK-nummer',
                value: offerte.kvk_nummer
            },
            {
                label: 'Bedrijfsnaam',
                value: offerte.klant.bedrijfsnaam
            },
            {
                label: 'Straat & huisnummer',
                value: offerte.klant.straatnaam
            },
            {
                label: 'Postcode & plaats',
                value: offerte.klant.postcode + ' ' + offerte.klant.plaatsnaam
            },
            {
                label: 'Contactpersoon',
                value: offerte.contactpersoon.voornaam + ' ' + offerte.contactpersoon.achternaam
            },
            {
                label: 'Telefoon',
                value: offerte.contactpersoon.telefoonnummer
            },
            {
                label: 'E-mail',
                value: offerte.contactpersoon.emailadres
            },
            {
                label: 'BTW nummer',
                value: offerte.klant.btw_nummer
            },
            {
                label: 'Geldig van',
                value: moment(offerte.geldig_van).format('DD-MM-YYYY')
            },
            {
                label: 'Geldig tot',
                value: moment(offerte.geldig_tot).format('DD-MM-YYYY')
            }
        ],
        factuuradres: [
            {
                label: 'Bedrijfsnaam',
                value: offerte.klant.bedrijfsnaam
            },
            {
                label: 'Telefoon administratie',
                value: offerte.klant.telefoon_administratie
            },
            {
                label: 'E-mail administratie',
                value: offerte.klant.email_administratie
            },
            {
                label: 'E-mail facturatie',
                value: offerte.klant.email_facturatie
            }
        ]
    };

    return (
        <Document>

            {/* Voorblad */}
            <Page size="A4" style={styles.firstPage} wrap={true}>
                <View style={styles.imageSection}>
                    <View style={styles.voorbladImage}><Image src={environment + offerte.divisie.afbeelding_voorblad.url} /></View>
                    <View style={styles.headerLogo}><Image src={pdfHeader} /></View>
                </View>
                <View style={styles.content}>
                    <View>
                        <Text style={styles.title}>{offerte.klant.nieuwe_klant ? 'Offerte' : 'Tariefblad'} {offerte.divisie.label}</Text>
                        <Text style={styles.subtitle}>{offerte.vestiging ? offerte.vestiging.naam : ''}</Text>
                    </View>
                    <View>
                        <Text style={styles.subtitle}>{offerte.klant.nieuwe_klant ? 'Offerte' : 'Tarieven'} voor:</Text>
                        <Text style={styles.receiverInfo}>{offerte.klant.bedrijfsnaam}</Text>
                        {/* <Text style={styles.receiverInfo}>{offerte.contactpersoon.voornaam + ' ' + offerte.contactpersoon.achternaam}</Text> */}
                        <Text style={styles.receiverInfo}>{offerte.straatnaam}</Text>
                        <Text style={styles.receiverInfo}>{offerte.postcode + ' ' + offerte.plaatsnaam}</Text>
                    </View>
                    <Text style={styles.version}>Versie: {props.versie ? props.versie : offerte.versies.length + 1}</Text>
                </View>
            </Page>

            {/* Voorwoord */}
            <Page size="A4" style={styles.page} wrap={true}>
                <PageHeader />

                <Text style={styles.pageTitle}>Voorwoord</Text>
                <Text style={styles.paragraph}>{offerte.aanhef}</Text>
                <Text style={styles.paragraph}>{offerte.omschrijving}</Text>

                <PageFooter pageNumber={2} />
            </Page>

            {/* Inhoudsopgave */}
            <Page size="A4" style={styles.page} wrap={true}>
                <PageHeader />
                <Inhoudsopgave 
                    offerte={offerte}
                    groupedPricesFtl={groupedPricesFtl}
                    groupedZones={groupedZones}
                    pageNumberZones={zonesPageNumber}
                    pagesToeslagen={pagesToeslagen}
                    firstPageToeslagen={firstPageToeslagen}
                    itemNumberVrachtToeslagen={itemNumberVrachtToeslagen}
                    pagesAlgemeneInformatie={pagesAlgemeneInformatie}
                    styles={styles} 
                />
                <PageFooter pageNumber={3} />
            </Page>




            {/* Prijslijst */}

            {
                offerte.ltl ?
                <>
                {
                    groupedZones.map((item, index) => {
                        return <Page key={index} size="A4" style={styles.page} wrap={true}>
                            <PageHeader />

                            {index === 0 ? <Text style={styles.pageTitle}>1. Tarieven</Text> : null}
                            {index === 0 ? <Text style={styles.subHeader}>{'1.1 Prijslijst LTL'}</Text> : null}
                            
                            <View key={index} style={styles.priceList}>
                                <View style={styles.tableInhouden}>
                                    <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}> 
                                        {
                                            arrSoorten.map((ladingItem, ladingIndex) =>  {
                                                return <Text key={ladingIndex} style={styles.tableCellHeaderLadingSoort}>{ladingItem}</Text> 
                                            })
                                        }
                                    </View>
                                    <View style={styles.tableRow}>
                                        {
                                            arrLabels.map((labelItem, labelIndex) => {
                                                return <View key={labelIndex} style={[styles.tableCol, styles.priceTableCol, styles.redCol, {width: priceColWidth}]}> 
                                                            <Text style={[styles.tableCell, styles.priceListTableCell, styles.tableCellRed]}>{labelItem}</Text> 
                                                        </View>
                                            })
                                        }
                                    </View>
                                    <>
                                    {
                                        offerte.rijen_inhouden ?
                                        <>
                                        {
                                            offerte.rijen_inhouden.map((subItem, subIndex) => {
                                                return <View key={subIndex} style={styles.tableRow}>
                                                            {
                                                                subItem[0].items.map((secondSubItem, secondSubIndex) => {
                                                                    return <View key={secondSubIndex} style={[styles.tableCol, styles.priceTableCol, {width: priceColWidth}]}> 
                                                                                <Text style={[styles.tableCell, styles.priceListTableCell]}>{secondSubItem}</Text> 
                                                                            </View>
                                                                })
                                                            }
                                                        </View>
                                            })
                                        }
                                        </>
                                        :
                                        null
                                    }
                                    </>
                                    
                                </View>

                                {
                                    item.map((zoneItem, zoneIndex) => {
                                        return <View key={zoneIndex} style={[styles.tablePrices, {width: (75 / item.length) + '%'}]}>
                                                    <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}> 
                                                        <Text style={[styles.tableCellHeader, {textAlign: 'center'}]}>{zoneItem.naam}</Text> 
                                                    </View>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.tableCol, styles.priceListCol, styles.redCol]}> 
                                                            <Text style={[styles.tableCell, styles.priceListTableCell, styles.tableCellRed]}>{'Totaal'}</Text> 
                                                        </View>
                                                    </View>
                                                    {
                                                        zoneItem.prijzen.map((priceItem, priceIndex) => {
                                                            return <View key={priceIndex} style={styles.tableRow}>
                                                                        <View style={[styles.tableCol, styles.priceListCol]}> 
                                                                            <Text style={[styles.tableCell, styles.priceListTableCell]}>{priceItem[0].value}</Text> 
                                                                        </View>
                                                                    </View>
                                                        })
                                                    }
                                                </View>
                                    })
                                }
                            </View>

                            <PageFooter pageNumber={4 + index} />
                        </Page>
                    })
                }
                </>
                :
                null
            }

            {
                offerte.ftl ?
                <>
                {
                    groupedPricesFtl.map((item, index) => {
                        return <Page key={index} size="A4" style={styles.page} wrap={true}>
                            <PageHeader />
                            {
                                !offerte.ltl ?
                                <>
                                    {index === 0 ? <Text style={styles.pageTitle}>1. Tarieven</Text> : null}
                                    {index === 0 ? <Text style={styles.subHeader}>{'1.1 Prijslijst'}</Text> : null}
                                </>
                                :
                                <>
                                    {index === 0 ? <Text style={styles.subHeader}>{'1.2 Prijslijst'}</Text> : null}
                                </>
                            }
                            
                            
                            <View key={index} style={styles.priceList}>
                                {
                                    item.map((priceItem, priceIndex) => {
                                        return <View key={priceIndex} style={[styles.tablePrices, {width: (98 / item.length) + '%'}]}>
                                                {
                                                    priceItem.prijzen.map((colItem, colIndex) => {
                                                        const rowHeight = colItem[0].height;
                                                        if (colIndex === 0) {
                                                            //return <View style={[styles.tableColHeader, {width: '100%'}]}>
                                                            return <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}>
                                                                <Text style={[styles.tableCellHeader, {textAlign: 'left'}]}>{colItem[0].value}</Text> 
                                                            </View>
                                                        } else {
                                                            return <View key={colIndex} style={styles.tableRow}>
                                                                    <View style={[styles.tableCol, styles.priceListCol, {height: rowHeight}]}> 
                                                                        <Text style={[styles.tableCell, {textAlign: 'left'}]}>{colItem[0].value}</Text> 
                                                                    </View>
                                                                </View>
                                                        }
                                                    })
                                                }
                                                </View>
                                    })
                                }
                            </View>

                            <PageFooter pageNumber={offerte.ltl ? 5 + index : 4 + index} />
                        </Page>
                    })
                }
                </>
                :
                null
            }

            {
                offerte.twee_ltl ?
                <>
                {
                    groupedZones.map((item, index) => {
                        return <Page key={index} size="A4" style={styles.page} wrap={true}>
                            <PageHeader />

                            {index === 0 ? <Text style={styles.pageTitle}>1. Tarieven</Text> : null}
                            {index === 0 ? <Text style={styles.subHeader}>{'1.1 Prijslijst LTL'}</Text> : null}
                            
                            <View key={index} style={styles.priceList}>
                                <View style={styles.tableInhouden}>
                                    <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}> 
                                        {
                                            arrSoorten.map((ladingItem, ladingIndex) =>  {
                                                return <Text key={ladingIndex} style={styles.tableCellHeaderLadingSoort}>{ladingItem}</Text> 
                                            })
                                        }
                                    </View>
                                    <View style={styles.tableRow}>
                                        {
                                            arrLabels.map((labelItem, labelIndex) => {
                                                return <View key={labelIndex} style={[styles.tableCol, styles.priceTableCol, styles.redCol, {width: priceColWidth}]}> 
                                                            <Text style={[styles.tableCell, styles.priceListTableCell, styles.tableCellRed]}>{labelItem}</Text> 
                                                        </View>
                                            })
                                        }
                                    </View>
                                    <>
                                    {
                                        offerte.rijen_inhouden ?
                                        <>
                                        {
                                            offerte.rijen_inhouden.map((subItem, subIndex) => {
                                                return <View key={subIndex} style={styles.tableRow}>
                                                            {
                                                                subItem[0].items.map((secondSubItem, secondSubIndex) => {
                                                                    return <View key={secondSubIndex} style={[styles.tableCol, styles.priceTableCol, {width: priceColWidth}]}> 
                                                                                <Text style={[styles.tableCell, styles.priceListTableCell]}>{secondSubItem}</Text> 
                                                                            </View>
                                                                })
                                                            }
                                                        </View>
                                            })
                                        }
                                        </>
                                        :
                                        null
                                    }
                                    </>
                                    
                                </View>

                                {
                                    item.map((zoneItem, zoneIndex) => {
                                        return <View key={zoneIndex} style={[styles.tablePrices, {width: (75 / item.length) + '%'}]}>
                                                    <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}> 
                                                        <Text style={[styles.tableCellHeader, {textAlign: 'center'}]}>{zoneItem.naam}</Text> 
                                                    </View>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.tableCol, styles.priceListCol, styles.redCol]}> 
                                                            <Text style={[styles.tableCell, styles.priceListTableCell, styles.tableCellRed]}>{'Totaal'}</Text> 
                                                        </View>
                                                    </View>
                                                    {
                                                        zoneItem.prijzen.map((priceItem, priceIndex) => {
                                                            return <View key={priceIndex} style={styles.tableRow}>
                                                                        <View style={[styles.tableCol, styles.priceListCol]}> 
                                                                            <Text style={[styles.tableCell, styles.priceListTableCell]}>{priceItem[0].value}</Text> 
                                                                        </View>
                                                                    </View>
                                                        })
                                                    }
                                                </View>
                                    })
                                }
                            </View>

                            <PageFooter pageNumber={4 + index} />
                        </Page>
                    })
                }
                </>
                :
                null
            }

            {
                offerte.twee_ltl ?
                <>
                {
                    groupedZonesTwee.map((item, index) => {
                        return <Page key={index} size="A4" style={styles.page} wrap={true}>
                            <PageHeader />
                            {index === 0 ? <Text style={styles.subHeader}>{'Prijslijst 2 LTL'}</Text> : null}
                            <View key={index} style={styles.priceList}>
                                <View style={styles.tableInhouden}>
                                    <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}> 
                                        {
                                            arrSoorten.map((ladingItem, ladingIndex) =>  {
                                                return <Text key={ladingIndex} style={styles.tableCellHeaderLadingSoort}>{ladingItem}</Text> 
                                            })
                                        }
                                    </View>
                                    <View style={styles.tableRow}>
                                        {
                                            arrLabelsTwo.map((labelItem, labelIndex) => {
                                                return <View key={labelIndex} style={[styles.tableCol, styles.priceTableCol, styles.redCol, {width: priceColWidth}]}> 
                                                            <Text style={[styles.tableCell, styles.priceListTableCell, styles.tableCellRed]}>{labelItem}</Text> 
                                                        </View>
                                            })
                                        }
                                    </View>
                                    <>
                                    {
                                        offerte.rijen_inhouden_twee ?
                                        <>
                                        {
                                            offerte.rijen_inhouden_twee.map((subItem, subIndex) => {
                                                return <View key={subIndex} style={styles.tableRow}>
                                                            {
                                                                subItem[0].items.map((secondSubItem, secondSubIndex) => {
                                                                    return <View key={secondSubIndex} style={[styles.tableCol, styles.priceTableCol, {width: priceColWidth}]}> 
                                                                                <Text style={[styles.tableCell, styles.priceListTableCell]}>{secondSubItem}</Text> 
                                                                            </View>
                                                                })
                                                            }
                                                        </View>
                                            })
                                        }
                                        </>
                                        :
                                        null
                                    }
                                    </>
                                    
                                </View>

                                {
                                    item.map((zoneItem, zoneIndex) => {
                                        return <View key={zoneIndex} style={[styles.tablePrices, {width: (75 / item.length) + '%'}]}>
                                                    <View style={[styles.tableColHeader, {width: '100%', height: arrSoorten.length * 13, minHeight: 18}]}> 
                                                        <Text style={[styles.tableCellHeader, {textAlign: 'center'}]}>{zoneItem.naam}</Text> 
                                                    </View>
                                                    <View style={styles.tableRow}>
                                                        <View style={[styles.tableCol, styles.priceListCol, styles.redCol]}> 
                                                            <Text style={[styles.tableCell, styles.priceListTableCell, styles.tableCellRed]}>{'Totaal'}</Text> 
                                                        </View>
                                                    </View>
                                                    {
                                                        zoneItem.prijzen.map((priceItem, priceIndex) => {
                                                            return <View key={priceIndex} style={styles.tableRow}>
                                                                        <View style={[styles.tableCol, styles.priceListCol]}> 
                                                                            <Text style={[styles.tableCell, styles.priceListTableCell]}>{priceItem[0].value}</Text> 
                                                                        </View>
                                                                    </View>
                                                        })
                                                    }
                                                </View>
                                    })
                                }
                            </View>

                            <PageFooter pageNumber={''} />
                        </Page>
                    })
                }
                </>
                :
                null
            }


            {/* Zones */}
            {
                offerte.zones.length > 0 ?
                <Page size="A4" style={styles.page} wrap={true}>
                    <PageHeader />

                    <Text style={styles.subHeader}>{offerte.ftl && offerte.ltl ? '1.3 Zones' : '1.2 Zones'}</Text>

                    <View style={styles.zonelist}>
                {
                    offerte.zones.map((item, index) => {
                        return <View key={index} style={[styles.table, styles.zoneTable]}>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCellHeader}>{item.naam}</Text>
                                    </View>
                                        {
                                            item.postcodes.map((secondSubItem, secondSubIndex) => {
                                                return <View key={secondSubIndex} style={styles.tableCol}> 
                                                            <Text style={styles.tableCell}>{secondSubItem.postcode}</Text> 
                                                        </View>
                                            })
                                        }
                                </View>
                    })
                }
                </View>

                    {
                    offerte.land ?
                    <Image src={environment + offerte.land.kaart.url} style={{maxHeight: 450, objectFit: 'contain'}} />
                    :
                    null
                    }
                    

                    <PageFooter pageNumber={zonesPageNumber} />
                </Page>
                :
                null
            }
            



            {/* Toeslagen */}
            {
                pagesToeslagen.map((item, index) => {
                    return <Page key={index} size="A4" style={styles.page} wrap={true}>
                                <PageHeader />
                                {
                                    index === 0 ?
                                    <Text style={styles.pageTitle}>2. Toeslagen</Text>
                                    :
                                    null
                                }
                                {
                                    item.map((firstSubItem, firstSubIndex) => {
                                        return <View key={firstSubIndex} style={styles.toeslagenSubItem}>
                                                <Text style={styles.subHeader}>{'2.' + firstSubItem.itemNumber + '. ' + firstSubItem.titel}</Text>
                                                { firstSubItem.alinea_een ? <Text style={styles.alinea}>{firstSubItem.alinea_een}</Text> : null }
                                                <View style={styles.table}>
                                                    <View style={styles.tableRow}>
                                                        {
                                                            firstSubItem.columns.map((secondSubItem, secondSubIndex) => {
                                                                const className = firstSubItem.columns.length === 4 ? 'fourCol' : 'twoCol';
                                                                return  <View key={secondSubIndex} style={[styles.tableColHeader, styles[className]]}> 
                                                                            <Text style={styles.tableCellHeader}>{secondSubItem}</Text> 
                                                                        </View>
                                                            })
                                                        }
                                                    </View> 
                                                    {
                                                        firstSubItem.table_contents.map((secondSubItem, secondSubIndex) => {

                                                            if (firstSubItem.columns.length === 4) {
                                                                return  <View key={secondSubIndex} style={styles.tableRow}> 
                                                                        <View style={[styles.tableCol, styles.fourCol]}> 
                                                                            <Text style={styles.tableCell}>{secondSubItem.toeslag.label}</Text> 
                                                                        </View> 
                                                                        <View style={[styles.tableCol, styles.fourCol]}> 
                                                                            <Text style={styles.tableCell}>{secondSubItem.eenheid}</Text> 
                                                                        </View> 
                                                                        <View style={[styles.tableCol, styles.fourCol]}> 
                                                                            <Text style={styles.tableCell}>{formatBedrag(secondSubItem.per_eenheid)}</Text> 
                                                                        </View>
                                                                        <View style={[styles.tableCol, styles.fourCol]}> 
                                                                            <Text style={styles.tableCell}>{secondSubItem.opmerking}</Text> 
                                                                        </View>
                                                                    </View>
                                                            } else {
                                                                return  <View key={secondSubIndex} style={styles.tableRow}> 
                                                                        <View style={[styles.tableCol, styles.twoCol]}> 
                                                                            <Text style={styles.tableCell}>{secondSubItem.naam}</Text> 
                                                                        </View> 
                                                                        <View style={[styles.tableCol, styles.twoCol]}> 
                                                                            <Text style={styles.tableCell}>{secondSubItem.laad_lostijd}</Text> 
                                                                        </View>
                                                                    </View>
                                                            }
                                                            
                                                        })
                                                    }
                                                </View>
                                                { firstSubItem.alinea_twee ? <Text style={styles.alinea}>{firstSubItem.alinea_twee}</Text> : null }
                                            </View>
                                    })
                                }
                                <PageFooter pageNumber={index + firstPageToeslagen} />
                            </Page>
                })
            }

            {/* Vrachttoeslagen */}
            <Page size="A4" style={styles.page} wrap={true}>
                <PageHeader />

                <Text style={styles.subHeader}>{'2.' + itemNumberVrachtToeslagen + '. ' + 'Vrachttoeslagen'}</Text>
                {
                    offerte.divisie.vrachttoeslagen.map((item, index) =>  {
                        return <View key={index} style={styles.toeslagenSubItem}>
                                    <Text style={styles.subItemHeader}>{item.titel}</Text>
                                    <Text style={styles.alinea}>{item.content}</Text>
                                </View>
                    })
                }

                <PageFooter pageNumber={pagesToeslagen.length + firstPageToeslagen} />
            </Page>

            {/* Overige afspraken */}
            {
                offerte.overige_afspraken ?
                <Page size="A4" style={styles.page} wrap={true}>
                    <PageHeader />

                    <Text style={styles.subHeader}>{'Overige afspraken'}</Text>
                    <View style={styles.toeslagenSubItem}>
                        <Text style={styles.alinea}>{offerte.overige_afspraken}</Text>
                    </View>

                    <PageFooter pageNumber={pagesToeslagen.length + firstPageToeslagen + 1} />
                </Page>
                :
                null
            }

            {/* Algemene informatie */}
            {
                pagesAlgemeneInformatie.map((item, index) => {
                    return <Page key={index} size="A4" style={styles.page} wrap={true}>
                                <PageHeader />
                                {
                                    index === 0 ?
                                    <Text style={styles.pageTitle}>3. Algemene informatie</Text>
                                    :
                                    null
                                }
                                {
                                    item.map((firstSubItem, firstSubIndex) => {
                                        return <View key={firstSubIndex} style={styles.toeslagenSubItem}>
                                                <Text style={styles.subHeader}>{'3.' + firstSubItem.itemNumber + '. ' + firstSubItem.titel}</Text>
                                                {
                                                    firstSubItem.subonderwerpen.map((secondSubItem, secondSubIndex) => {
                                                        return <View key={secondSubIndex} style={styles.toeslagenSubItem}>
                                                                <Text style={styles.subItemHeader}>{secondSubItem.titel}</Text>
                                                                <Text style={styles.alinea}>{secondSubItem.content}</Text>
                                                        </View>
                                                    })
                                                }
                                            </View>
                                    })
                                }
                                <PageFooter pageNumber={index + ((pagesToeslagen.length + firstPageToeslagen) + 1)} />
                            </Page>
                })
            }


            {/* Ondertekening */}
            <Page size="A4" style={styles.page} wrap={true}>
                <PageHeader />

                <Text style={styles.pageTitle}>Ondertekening</Text>

                <View style={styles.signBlock}>
                    <Text style={styles.signBlockHeader}>Uw gegevens</Text>
                    {
                        signing.gegevens.map((item, index) =>  {
                            return <View key={index} style={styles.signItem}>
                                <Text style={styles.signItemLabel}>{item.label}:</Text>
                                <Text style={styles.signItemValue}>{item.value}</Text>
                            </View>          
                        })
                    }
                </View>

                {
                    offerte.contactpersoon.emailadres !== offerte.contactpersoon_facturatie.emailadres ?
                    <View style={styles.signBlock}>
                        <Text style={styles.signBlockHeader}>Factuuradres</Text>
                        {
                            signing.factuuradres.map((item, index) =>  {
                                return <View key={index} style={styles.signItem}>
                                    <Text style={styles.signItemLabel}>{item.label}:</Text>
                                    <Text style={styles.signItemValue}>{item.value}</Text>
                                </View>          
                            })
                        }
                    </View>
                    :
                    null
                }

                <View style={styles.signBlock}>
                    <Text style={styles.signBlockHeader}>Ondertekening voor akkoord</Text>
                    <Text style={styles.signAlinea}>Door ondertekening van deze offerte verklaar je een exemplaar van de Algemene voorwaarden VSDV, CMR en de AVC condities te hebben ontvangen, die beiden op deze offerte van toepassing zijn. Aldus getekend in tweevoud:</Text>
                    <View style={styles.signColumns}>
                        <View style={styles.signColumn}>
                            <View style={styles.signInfo}>
                                <Text style={styles.signDocumentLabel}>Plaats:</Text>
                                <Text style={styles.signDocumentValue}>Zwaag</Text>
                            </View>
                            <View style={styles.signInfo}>
                                <Text style={styles.signDocumentLabel}>Datum:</Text>
                                <Text style={styles.signDocumentValue}>{moment(offerte.datum).format('DD-MM-YYYY')}</Text>
                            </View>
                            <View style={styles.signature}>
                                <Text style={styles.companyName}>Van Straalen de Vries Transport</Text>
                                <View style={styles.signatureArea}>
                                    {
                                        offerte.aangemaakt_voor.handtekening ?
                                        <Image src={environment + offerte.aangemaakt_voor.handtekening.url} style={styles.signatureImage} />
                                        :
                                        null
                                        
                                    }
                                </View>
                                <Text style={styles.signDocumentLabel}>{offerte.aangemaakt_voor ? offerte.aangemaakt_voor.voornaam + ' ' + offerte.aangemaakt_voor.achternaam : ''}</Text>
                            </View>
                        </View>
                        <View style={styles.signColumn}>
                            <View style={styles.signInfo}>
                                <Text style={styles.signDocumentLabel}>Plaats:</Text>
                                <Text style={styles.signDocumentValue}>{props.signPlace ? props.signPlace : ''}</Text>
                            </View>
                            <View style={styles.signInfo}>
                                <Text style={styles.signDocumentLabel}>Datum:</Text>
                                <Text style={styles.signDocumentValue}>{props.signPlace ? moment().format('DD-MM-YYYY') : ''}</Text>
                            </View>
                            <View style={styles.signature}>
                            <Text style={styles.companyName}>{props.signCompany ? props.signCompany : offerte.klant.bedrijfsnaam}</Text>
                                <View style={styles.signatureArea}>
                                    {
                                        props.signatureImage ?
                                        <Image src={props.signatureImage} style={styles.signatureImage} />
                                        :
                                        null
                                    }
                                </View>
                                {
                                    props.signFirstName ?
                                    <Text style={styles.signDocumentLabel}>{props.signFirstName ? props.signFirstName + ' ' + props.signLastName : ''}</Text>
                                    :
                                    <Text style={styles.signDocumentLabel}>{offerte.contactpersoon.voornaam + ' ' + offerte.contactpersoon.achternaam}</Text>
                                }
                                
                            </View>
                        </View>
                    </View>
                </View>
                

                <PageFooter pageNumber={(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 1} />
            </Page>


            {/* Bijlagen */}
            {
                offerte.bijlagen.length > 0 ?
                <Page size="A4" style={styles.page} wrap={true}>
                    <PageHeader />
                    <Text style={styles.pageTitle}>Bijlagen</Text>

                    <View>
                    {
                        offerte.bijlagen.map((item, index) =>  {
                            return <View key={index} style={styles.bijlageItem}>
                                <Text style={styles.bijlageNumber}>{'Bijlage ' + (index + 1)}:</Text>
                                <Text style={styles.bijlageName}>{item.naam}</Text>
                            </View>          
                        })
                    }
                    {
                        offerte.dieseltoeslag ?
                        <View style={styles.bijlageItem}>
                            <Text style={styles.bijlageNumber}>{'Bijlage ' + (offerte.bijlagen.length + 1)}:</Text>
                            <Text style={styles.bijlageName}>{offerte.soort_dieseltoeslag.label}</Text>
                        </View>
                        :
                        null
                    }
                    </View>

                    <PageFooter pageNumber={(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2} />
                </Page>
                :
                <>
                {
                    offerte.dieseltoeslag ?
                    <Page size="A4" style={styles.page} wrap={true}>
                        <PageHeader />
                        <Text style={styles.pageTitle}>Bijlagen</Text>
                        <View>
                            <View style={styles.bijlageItem}>
                                <Text style={styles.bijlageNumber}>{'Bijlage 1:'}</Text>
                                <Text style={styles.bijlageName}>{offerte.soort_dieseltoeslag.label}</Text>
                            </View>
                        </View>
                        <PageFooter pageNumber={(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2} />
                    </Page>
                    :
                    null
                }  
                </>
            }
            

        </Document>
    );
};
export default OfferteDocument;