import axios from 'axios';

//Set API url for staging or local development
const instance = axios.create({
    //baseURL: process.env.REACT_APP_API_URL_LOCAL
    //baseURL: process.env.REACT_APP_API_URL_STAGING
    baseURL: process.env.REACT_APP_API_URL_PRODUCTION
})

instance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('bearerToken');
        let bearer = '';
        if (token) {
            bearer = token;
        }
        if (bearer !== '') {
            if (config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${token}`;
            } else {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default instance;