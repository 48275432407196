import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, formatCurrency } from '../../../settings/Helpers';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Rating from 'react-rating';
import ReactSpeedometer from "react-d3-speedometer";
import Rodal from 'rodal';
import { Tooltip } from 'react-tooltip';
import { exportToExcel } from 'react-json-to-excel';
import { Drawer, Timeline, Loader } from 'rsuite';
import { confirmAlert } from 'react-confirm-alert';

//API
import api from '../../../settings/AxiosSetup';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import Funnel from '../../../components/rapportage/funnel';
import DoughnutChart from '../../../components/rapportage/doughnut';
import HalfDoughnutChart from '../../../components/rapportage/halfDoughnut';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './rapportage.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'rodal/lib/rodal.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'rsuite/dist/rsuite-no-reset.min.css';

const Rapportage = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const [questions, setQuestions] = useState([]);
    const [totalEnquetes, setTotalEnquetes] = useState(0);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [initialYear, setInitialYear] = useState(moment().format('Y'));
    const currentYear = moment().format('Y');
    const yearsArray = [];
    for (let i = -2; i <= 1; i++) {
        yearsArray.push(moment(currentYear, 'YYYY').add(i, 'years').format('YYYY'));
    }
    const [sort, setSort] = useState('desc');
    const [topClients, setTopClients] = useState([]);
    const [loadingClients, setLoadingClients] = useState(false);
    const [loadingLeads, setLoadingLeads] = useState(false);
    const [leads, setLeads] = useState([]);
    const [omzet, setOmzet] = useState(0);
    const [speedoMeterOmzet, setSpeedometerOmzet] = useState(0);
    const [target, setTarget] = useState(0);
    const [kpiYear, setKpiYear] = useState(moment().format('Y'));
    const [loadingKpi, setLoadingKpi] = useState(false);
    const [loadingConversion, setLoadingConversion] = useState(false);
    const [funnelData, setFunnelData] = useState({
        labels: [],
        data: [],
        omzetschattingen: []
    });
    const [excelData, setExcelData] = useState([]);
    const [newFunnelData, setNewFunnelData] = useState([]);
    const [divisie, setDivisie] = useState(0);
    const [divisieLabel, setDivisieLabel] = useState('Alle divisies');
    const [vestiging, setVestiging] = useState(0);
    const [vestigingLabel, setVestigingLabel] = useState('Alle vestigingen');
    const [vestigingOptions, setVestigingOptions] = useState([]);
    const [divisieOptions, setDivisieOptions] = useState([]);
    const [accountmanager, setAccountmanager] = useState(0);
    const [accountmanagerLabel, setAccountmanagerLabel] = useState('Alle accountmanagers');
    const [accountmanagerOptions, setAccountmanagerOptions] = useState([]);
    const [datumVan, setDatumVan] = useState('');
    const [datumTot, setDatumTot] = useState('');
    const [conversionNumber, setConversionNumber] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('Lead toevoegen');
    const [addingNewLead, setAddingNewLead] = useState(true);
    const [convertingLead, setConvertingLead] = useState(false);
    const [leadToConvert, setLeadToConvert] = useState('');
    const [leadName, setLeadName] = useState('');
    const [leadPostcode, setLeadPostcode] = useState('');
    const [leadPlaatsnaam, setLeadPlaatsnaam] = useState('');
    const [leadStraatnaam, setLeadStraatnaam] = useState('');
    const [leadLand, setLeadLand] = useState('');
    const [leadDivisie, setLeadDivisie] = useState('');
    const [leadVestiging, setLeadVestiging] = useState('');
    const [leadAccountManager, setLeadAccountManager] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [drawerTimeline, setDrawerTimeline] = useState([]);
    const [currentLeadInfo, setCurrentLeadInfo] = useState('');
    const [timelineDescription, setTimelineDescription] = useState('');
    const [addingTimeline, setAddingTimeline] = useState(false);

    const [doughnutData, setDougnutData] = useState({
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              'rgba(0, 28, 67, 1.0)',
              'rgba(176, 199, 212, 1.0)',
              'rgba(203, 10, 50, 1.0)',
            ],
            borderColor: [
              'rgba(0, 28, 67, 1.0)',
              'rgba(176, 199, 212, 1.0)',
              'rgba(203, 10, 50, 1.0)',
            ],
          },
        ],
    });

    const lightenColor = (color, percent) => {
        // Parse hex color to RGB
        let num = parseInt(color.slice(1), 16),
            amt = Math.round(2.55 * percent),
            r = (num >> 16) + amt,
            b = (num >> 8 & 255) + amt,
            g = (num & 255) + amt;
        // Ensure values are within 0-255 range
        r = Math.min(255, Math.max(0, r));
        g = Math.min(255, Math.max(0, g));
        b = Math.min(255, Math.max(0, b));
        // Convert back to hex
        return "#" + (g | b << 8 | r << 16).toString(16).padStart(6, '0');
    }

    const getRapportage = async () => {
        setLoadingQuestions(true);
        try {
            const response = await api.post('/rapportage', {
                data: {
                    divisie: divisie,
                    vestiging: vestiging,
                    accountmanager: accountmanager
                }
            });
            const feedback = [];
            const dataArray = Object.entries(response.data.offertes).map(([key, value]) => ({ key, ...value }));
            const sortedArray = dataArray.sort((a, b) => b.omzetschatting - a.omzetschatting);
            const maxOmzetschatting = sortedArray[0].omzetschatting;
            sortedArray.forEach((item, index) => {
                item.width = Math.floor((item.omzetschatting / maxOmzetschatting) * 100);
                item.width = Math.max(item.width, 30);
                const shadePercentage = index * 10; // Adjust this value to control the lightness increment
                const color = lightenColor('#001C43', shadePercentage);
                item.color = color;
            });
            setNewFunnelData(sortedArray);
            createFunnelData(response.data.offertes);
            setExcelData(response.data.excelData);
            setTotalEnquetes(response.data.feedback.length);
            response.data.feedback.map((item, index) =>  {
                item.vragen.map((subItem, subIndex) =>  {
                    const findIndex = feedback.findIndex(arrayItem => arrayItem.id === subItem.vraag.id);
                    if (findIndex !== -1) {
                        feedback[findIndex].rating += subItem.rating;
                        feedback[findIndex].count += 1;
                    } else {
                        const obj = {
                            id: subItem.vraag.id,
                            rating: subItem.rating,
                            count: 1,
                            vraag: subItem.vraag.vraag
                        };
                        feedback.push(obj);
                    }
                })
            })
            let totalRating = 0;
            feedback.map((item, index) =>  {
                totalRating += item.rating / item.count;
            })
            setQuestions(feedback);
            setTotalScore(totalRating / feedback.length);
            setLoadingQuestions(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getTopklanten = async (year, sorting) => {
        setLoadingClients(true);
        setInitialYear(year);
        try {
            const response = await api.post('/topklanten', {
                data: {
                    year: year,
                    divisie: divisie,
                    vestiging: vestiging,
                    accountmanager: accountmanager,
                    sort: sorting
                }
            });
            const clients = [];
            response.data.map((item, index) =>  {
                const obj = {
                    bedrijfsnaam: item.klant.bedrijfsnaam,
                    omzetschatting: item.omzetschatting
                };
                clients.push(obj);
            })
            setTopClients(clients);
            setLoadingClients(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getLeads = async () => {
        setLoadingLeads(true);
        try {
            const response = await api.post('/get-leads', {
                data: {
                    divisie: divisie,
                    vestiging: vestiging,
                    accountmanager: accountmanager
                }
            });
            setLeads(response.data);
            setShowModal(false);
            setLeadName('');
            setLeadDivisie('');
            setLeadVestiging('');
            setLeadAccountManager('');
            setLoadingLeads(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getKpi = async (year) => {
        setLoadingKpi(true);
        setKpiYear(year);
        try {
            const response = await api.post('/kpi', {
                data: {
                    year: Number(year),
                    accountmanager: accountmanager,
                    divisie: divisie,
                    vestiging: vestiging
                }
            });
            if (response.data.omzet > response.data.target) {
                setSpeedometerOmzet(response.data.target);
            } else {
                setSpeedometerOmzet(response.data.omzet);
            }
            setOmzet(response.data.omzet);
            setTarget(response.data.target);
            setLoadingKpi(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getConversion = async () => {
        setLoadingConversion(true);
        try {
            const response = await api.post('/conversie', {
                data: {
                    divisie: divisie,
                    vestiging: vestiging,
                    accountmanager: accountmanager,
                    datum_van: datumVan,
                    datum_tot: datumTot
                }
            });
            setConversionNumber((response.data.goedgekeurd / response.data.verstuurd).toFixed(2));
            createDougnutData(response.data);
            setLoadingConversion(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getOptions = async () => {
        try {
            const response = await api.get('/rapportage-opties');
            if (response.data) {
                setDivisieOptions(response.data.divisies);
                setVestigingOptions(response.data.vestigingen);
                setAccountmanagerOptions(response.data.accountmanagers);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addLead = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/leads', {
                data: {
                    naam: leadName,
                    vestiging: {id: leadVestiging},
                    divisie: {id: leadDivisie},
                    accountmanager: {id: leadAccountManager},
                }
            });
            if (response.data) {
                notifySuccess('Nieuwe lead succesvol toegevoegd');
                getLeads();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const convertLead = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/leads/nieuwe-klant', {
                data: {
                    id: leadToConvert,
                    bedrijfsnaam: leadName,
                    postcode: leadPostcode,
                    plaatsnaam: leadPlaatsnaam,
                    straatnaam: leadStraatnaam,
                    land: leadLand
                }
            });
            if (response.data) {
                notifySuccess(leadName + ' succesvol omgezet in klant');
                getLeads();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteLead = async (id) => {
        try {
            const response = await api.delete('/leads/' + id);
            if (response.data) {
                notifySuccess('Lead is succesvol verwijderd');
                getLeads();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const confirmDelete = (id) => {
        confirmAlert({
            title: 'De lead en alle bijbehorende informatie wordt hiermee compleet verwijderd',
            message: 'Wil je doorgaan?',
            buttons: [
                {
                label: 'Ja',
                onClick: () => deleteLead(id)
                },
                {
                label: 'Nee'
                }
            ]
        });
    }

    const createFunnelData = (offertes) => {
        const current = {...funnelData};
        // Sorting the keys based on the aantal value
        const sortedKeys = Object.keys(offertes).sort((a, b) => offertes[b].aantal - offertes[a].aantal);
        // Initializing arrays
        const labels = [];
        const data = [];
        const omzetschattingen = [];
        // Populating arrays
        sortedKeys.forEach(key => {
            labels.push(key.replace(/_/g, ' ')); // Replace underscores with spaces
            data.push(offertes[key].aantal);
            omzetschattingen.push(offertes[key].omzetschatting);
        });
        current.labels = labels;
        current.data = data;
        current.omzetschattingen = omzetschattingen;
        setFunnelData(current);
    }

    const createDougnutData = (items) => {
        const current = {...doughnutData};
        const sortedKeys = Object.keys(items).sort((a, b) => items[b] - items[a]);
        const labels = [];
        const data = [];
        sortedKeys.forEach(key => {
            labels.push(key.replace(/_/g, ' ')); // Replace underscores with spaces
            data.push(items[key]);
        });
        current.labels = labels;
        current.datasets[0].data = data;
        setDougnutData(current);
    }

    const refreshDashboard = () => {
        const divisieIndex = divisieOptions.findIndex(item => item.id === divisie);
        setDivisieLabel(divisieIndex > -1 ? divisieOptions[divisieIndex].label : 'Alle divisies');
        const vestigingIndex = vestigingOptions.findIndex(item => item.id === vestiging);
        setVestigingLabel(vestigingIndex > -1 ? vestigingOptions[vestigingIndex].naam : 'Alle vestigingen');
        const accountmanagerIndex = accountmanagerOptions.findIndex(item => item.id === accountmanager);
        setAccountmanagerLabel(accountmanagerIndex > -1 ? accountmanagerOptions[accountmanagerIndex].voornaam + ' ' + accountmanagerOptions[accountmanagerIndex].achternaam : 'Alle accountmanagers');
        getRapportage();
        getTopklanten(initialYear, sort);
        getLeads();
        getKpi(kpiYear);
        getConversion();
    }

    const showInfo = (index) => {
        setCurrentLeadInfo(leads[index].id);
        setDrawerTitle(leads[index].naam);
        setDrawerTimeline(leads[index].logs);
        setShowDrawer(true);
    }

    const addTimeline = async (event) => {
        event.preventDefault();
        setAddingTimeline(true);
        try {
            const response = await api.post('/leads/tijdlijn/' + currentLeadInfo, {
                data: {
                    log: timelineDescription
                }
            });
            if (response.data) {
                const findIndex = leads.findIndex(item => item.id === currentLeadInfo);
                if (findIndex !== -1) {
                    setDrawerTimeline(response.data.logs);
                    getLeads();
                    setTimelineDescription('');
                    notifySuccess('Item toegevoegd aan tijdlijn');
                    setAddingTimeline(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    

    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getOptions();
        getRapportage(divisie, accountmanager);
        getLeads();
        getKpi(initialYear);
        getTopklanten(initialYear, sort);
        getConversion();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/rapportage" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title={'Rapportage'} />

            <div className="rapportage-filters">
                <select onChange={(e) => setDivisie(Number(e.target.value))}>
                    <option value="0">Alle divisies</option>
                    {
                        divisieOptions.map((item, index) =>  {
                            return <option key={index} value={item.id}>{item.label}</option>
                        })
                    }
                </select>
                <select onChange={(e) => setVestiging(Number(e.target.value))}>
                    <option value="0">Alle vestigingen</option>
                    {
                        vestigingOptions.map((item, index) =>  {
                            return <option key={index} value={item.id}>{item.naam}</option>
                        })
                    }
                </select>
                <select onChange={(e) => setAccountmanager(Number(e.target.value))}>
                    <option value="0">Alle accountmanagers</option>
                    {
                        accountmanagerOptions.map((item, index) =>  {
                            return <option key={index} value={item.id}>{item.voornaam + ' ' + item.achternaam}</option>
                        })
                    }
                </select>
                <input type="date" placeholder="Van" value={datumVan} onChange={(e) => setDatumVan(e.target.value)} />
                <input type="date" placeholder="Tot" value={datumTot} onChange={(e) => setDatumTot(e.target.value)} />
                <button onClick={refreshDashboard}>Filter</button>
            </div>

            <div className="rapportage">

                {/* Topklanten */}
                <div className="block">
                    <div className="block-content">
                        <div className="block-header">
                            <Icon.BarChartSteps />
                            <h3>Pijplijn</h3>
                            <div className="block-meta">{divisieLabel} - {vestigingLabel} - {accountmanagerLabel}</div>
                            <Icon.FileEarmarkSpreadsheet size={22} color="#1E6E42" id={'tooltip-excel'} className="download-excel" onClick={() => {
                                var fileName = 'export-pijplijn-' + divisieLabel + '-' + vestigingLabel + '-' + accountmanagerLabel + '-' + moment().format('DD-MM-YYYY HH:mm');
                                exportToExcel(excelData, fileName, true);
                            }}/>
                            <Tooltip
                                anchorId={'tooltip-excel'}
                                place="top"
                                content={'Download excel huidige weergave pijplijn'}
                            />
                        </div>
                        {/* {
                            !loadingQuestions ?
                            <Funnel data={funnelData} />
                            :
                            <>
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            </>
                        } */}
                        {
                            !loadingQuestions && newFunnelData.length > 0 ?
                            <div className="custom-funnel">
                                {
                                    newFunnelData.map((item, index) => {
                                        let statusName = item.key.replace(/_/g, ' ');
                                        statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
                                        return <div className="funnel-item">
                                        <div className="funnel-block" style={{width: item.width + '%', maxWidth: '75%'}}>
                                            <div className="funnel-block-contents">
                                                <p>{formatCurrency(item.omzetschatting)}</p>
                                                <p className="status-name">{statusName}</p>
                                            </div>
                                        </div>
                                        <div className="funnel-item-meta">
                                            <span>{item.aantal + ' offerte(s)'}</span>
                                        </div>
                                    </div>
                                    })
                                }
                            </div>
                            :
                            <>
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            </>
                        }
                    </div>
                </div>

                {/* Leads */}
                <div className="block column-1-4">
                    <div className="block-content">
                        <div className="block-header">
                            <Icon.Alexa />
                            <h3>Leads</h3>
                            <Icon.PlusCircleFill size={22} color="#00d100" className="add-lead" onClick={() => {
                                setModalTitle('Lead toevoegen');
                                setLeadName('');
                                setLeadDivisie('');
                                setLeadVestiging('');
                                setLeadAccountManager('');
                                setLeadPostcode('');
                                setLeadPlaatsnaam('');
                                setLeadStraatnaam('');
                                setLeadLand(''); 
                                setShowModal(true); 
                                setAddingNewLead(true); 
                                setConvertingLead(false);
                            }}/>
                        </div>
                        {
                            loadingLeads ?
                            <>
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            </>
                            :
                            <>
                            {
                                leads.length > 0 ?
                                <div className="leads">
                                    {
                                        leads.map((item, index) =>  {
                                            return  <div key={index} className={'lead'}>
                                                        <div className="name">
                                                            <p>{item.naam}</p>
                                                            <span>{item.accountmanager.voornaam + ' ' + item.accountmanager.achternaam}</span>
                                                        </div>
                                                        <div className="lead-meta">
                                                            <div className="lead-meta-labels">
                                                                <span>{item.divisie.label}</span>
                                                                <span>{item.vestiging.naam}</span>
                                                            </div>
                                                            <div className="lead-actions">
                                                                <Icon.PersonCheck color="#00d100" size={24} id={'tooltip-' + index} className="new-client" onClick={() => {
                                                                    setModalTitle('Lead omzetten in klant');
                                                                    setShowModal(true); 
                                                                    setAddingNewLead(false); 
                                                                    setConvertingLead(true);
                                                                    setLeadToConvert(item.id);
                                                                    setLeadName(item.naam); 
                                                                }}/>
                                                                <Tooltip
                                                                anchorId={'tooltip-' + index}
                                                                place="top"
                                                                content={'Lead omzetten in klant'}
                                                                />
                                                                <div className="options">
                                                                    <Icon.InfoCircle id={'tooltip-info-' + index} color="#001C43" size={14} className="info" onClick={() => {showInfo(index)}}/>
                                                                    <Tooltip
                                                                    anchorId={'tooltip-info-' + index}
                                                                    place="top"
                                                                    content={'Meer info'}
                                                                    />
                                                                    <Icon.Trash3 id={'tooltip-delete-' + index} color="#CB0A32" size={14} className="info" onClick={() => {confirmDelete(item.id)}}/>
                                                                    <Tooltip
                                                                    anchorId={'tooltip-delete-' + index}
                                                                    place="top"
                                                                    content={'Lead definitef verwijderen'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        })
                                    }
                                </div>
                                :
                                <p>Geen leads gevonden...</p>
                            }
                            </>
                        }
                    </div>
                </div>

                {/* Conversie */}
                <div className="block column-1-4">
                    <div className="block-content">
                        <div className="block-header">
                            <Icon.Clipboard2Data />
                            <h3>Conversie</h3>
                            <div className="block-meta">{divisieLabel} - {vestigingLabel} - {accountmanagerLabel}</div>
                        </div>
                        <div className="dougnut">
                            { !loadingConversion ? <div className="conversion-rate"><span>{conversionNumber}</span></div> : null }
                            {
                                !loadingConversion ?
                                <DoughnutChart data={doughnutData} />
                                :
                                null
                            }
                        </div>
                        
                    </div>
                </div>

                {/* KPI */}
                <div className="block column-1-3">
                    <div className="block-content">
                        <div className="block-header">
                            <Icon.Speedometer2 />
                            <h3>KPI</h3>
                            <div className="block-meta">{divisieLabel} - {vestigingLabel} - {accountmanagerLabel}</div>
                        </div>
                        {
                            !loadingKpi ?
                            <div className="kpi">
                                <ReactSpeedometer
                                    width={400}
                                    height={200}
                                    value={speedoMeterOmzet}
                                    maxValue={target}
                                    segments={3}
                                    segmentColors={[
                                        "#F6961E",
                                        "#ECDB23",
                                        "#AEE228"
                                    ]}
                                    needleColor="steelblue"
                                    customSegmentLabels={[]}
                                />
                                <select name="kpi-year" defaultValue={kpiYear} onChange={(e) => getKpi(e.target.value)} id="kpi-year" className="form-field" required>
                                    {
                                        yearsArray.map((item, index) =>  {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                                <div className="kpi-meta">
                                    <div className="kpi-item"><span>Target</span><p>{formatCurrency(target)}</p></div>
                                    <div className="kpi-item"><span>Omzet</span><p>{formatCurrency(omzet)}</p></div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

                {/* Klanttevredenheid */}
                <div className="block column-2-3">
                    <div className="block-content">
                        <div className="block-header">
                            <Icon.ListStars />
                            <h3>Klanttevredenheidsscore</h3>
                        </div>
                        {
                            loadingQuestions ?
                            <>
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            <Skeleton height={40} style={{marginBottom:10}} />
                            </>
                            :
                            <>
                            {
                                questions.length > 0 ?
                                <div className="questions">
                                    {
                                        questions.map((item, index) =>  {
                                            return  <div key={index} className={'question'}>
                                                        <p>{item.vraag}</p>
                                                        <Rating 
                                                            initialRating={item.rating / item.count}
                                                            emptySymbol={<Icon.Star color="#F2CC00" size={20} />}
                                                            fullSymbol={<Icon.StarFill color="#F2CC00" size={20} />}
                                                            readonly
                                                        />
                                                    </div>
                                        })
                                    }
                                </div>
                                :
                                <p>Geen vragen gevonden...</p>
                            }
                            </>
                        }
                        
                        <div className="total-score">
                            <p>Algemene score</p>
                            <Rating 
                                initialRating={totalScore}
                                emptySymbol={<Icon.Star color="#F2CC00" size={32} />}
                                fullSymbol={<Icon.StarFill color="#F2CC00" size={32} />}
                                readonly
                            />
                            <p className="info-label">Op basis van {totalEnquetes} ingevulde enquetes</p>
                        </div>
                    </div>
                </div>

                {/* Topklanten */}
                <div className="block column-2-3">
                    <div className="block-content">
                        <div className="block-header">
                            <Icon.CurrencyEuro />
                            <h3>Topklanten</h3>
                            <div className="block-meta">{divisieLabel} - {vestigingLabel} - {accountmanagerLabel}</div>
                            <select defaultValue={sort} onChange={(e) => {getTopklanten(currentYear, e.target.value); setSort(e.target.value)}} className="form-field sorting-dropdown">
                                <option value="desc">Aflopend</option>
                                <option value="asc">Oplopend</option>
                            </select>
                            <select name="clients-year" defaultValue={currentYear} onChange={(e) => getTopklanten(e.target.value, sort)} id="clients-year" className="form-field" required>
                                {
                                    yearsArray.map((item, index) =>  {
                                        return <option key={index} value={item}>{item}</option>
                                    })
                                }
                            </select>
                        </div>
                        <p className="info-label">*Bedragen zijn op basis ingevoerde omzetschattingen</p>
                        <div className="top-clients">
                            {
                                loadingClients ?
                                <>
                                <Skeleton height={40} style={{marginBottom:10}} />
                                <Skeleton height={40} style={{marginBottom:10}} />
                                <Skeleton height={40} style={{marginBottom:10}} />
                                <Skeleton height={40} style={{marginBottom:10}} />
                                <Skeleton height={40} style={{marginBottom:10}} />
                                <Skeleton height={40} style={{marginBottom:10}} />
                                <Skeleton height={40} style={{marginBottom:10}} />
                                </>
                                :
                                <>
                                {
                                    topClients.map((item, index) =>  {
                                        return  <div key={index} className={'client'}>
                                                    <p>{item.bedrijfsnaam}</p>
                                                    <span>{formatCurrency(item.omzetschatting)}</span>
                                                </div>
                                    })
                                }
                                </>
                            }
                            
                        </div>
                    </div>
                </div>

            </div>

            <Rodal visible={showModal} onClose={() => { setShowModal(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>{modalTitle}</h3>
                {
                    addingNewLead ?
                    <form id="add-lead" className="popup-form" onSubmit={addLead}>
                        <div className="two-column">
                            <input type="text" value={leadName} onChange={(e) => setLeadName(e.target.value)} placeholder="Bedrijfsnaam" className="form-field" required />
                            <select onChange={(e) => setLeadDivisie(Number(e.target.value))} className="form-field" required>
                                <option disabled value="" selected>Alle divisies</option>
                                {
                                    divisieOptions.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.label}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="two-column">
                            <select onChange={(e) => setLeadVestiging(Number(e.target.value))} className="form-field" required>
                                <option disabled value="" selected>Alle vestigingen</option>
                                {
                                    vestigingOptions.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.naam}</option>
                                    })
                                }
                            </select>
                            <select onChange={(e) => setLeadAccountManager(Number(e.target.value))} className="form-field" required>
                                <option disabled value="" selected>Alle accountmanagers</option>
                                {
                                    accountmanagerOptions.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.voornaam + ' ' + item.achternaam}</option>
                                    })
                                }
                            </select>
                        </div>
                        <input type="submit" value="Lead aanmaken" className="green" />
                    </form>
                    :
                    null
                }
                {
                    convertingLead ?
                    <form id="convert-lead" className="popup-form" onSubmit={convertLead}>
                        <input type="text" value={leadName} onChange={(e) => setLeadName(e.target.value)} placeholder="Bedrijfsnaam" className="form-field" required />
                        <div className="two-column">
                            <input type="text" value={leadPostcode} onChange={(e) => setLeadPostcode(e.target.value)} placeholder="Postcode" className="form-field" required />
                            <input type="text" value={leadPlaatsnaam} onChange={(e) => setLeadPlaatsnaam(e.target.value)} placeholder="Plaatsnaam" className="form-field" required />
                        </div>
                        <div className="two-column">
                            <input type="text" value={leadStraatnaam} onChange={(e) => setLeadStraatnaam(e.target.value)} placeholder="Straatnaam" className="form-field" required />
                            <input type="text" value={leadLand} onChange={(e) => setLeadLand(e.target.value)} placeholder="Land (bijv. Nederland)" className="form-field" required />
                        </div>
                        <input type="submit" value="Omzetten" className="green" />
                    </form>
                    :
                    null
                }
            </Rodal>

            <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
                <Drawer.Header>
                <Drawer.Title>{drawerTitle}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div id="lead-timeline">
                    {
                        drawerTimeline.length > 0 ?
                        <Timeline>
                            {
                                drawerTimeline.map((item, index) =>  {
                                    return  <Timeline.Item>
                                                <span className="date">{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}</span>
                                                <span className="description">{item.omschrijving}</span>
                                            </Timeline.Item>
                                })
                            }
                        </Timeline>
                        :
                        <p>Nog geen tijdlijn ingevuld...</p>
                    }
                    </div>
                <form id="add-description" onSubmit={addTimeline}>
                    <textarea placeholder="Tijdlijn item toevoegen" value={timelineDescription} onChange={(e) => setTimelineDescription(e.target.value)} rows="4" required></textarea>
                    {
                        addingTimeline ?
                        <Loader />
                        :
                        <input type="submit" value="Item toevoegen" className="button" />
                    }
                    
                </form>
                </Drawer.Body>
            </Drawer>                

            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/rapportage" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Rapportage;